import React from 'react';
import PropTypes from 'prop-types';

const Warning = ({ className, width, height, viewBox, fill, stroke, strokeWidth }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1585_2968)">
      <path
        d="M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z"
        fill="#D97706"
      />
      <path
        d="M12 3C10.9 3 10 3.9 10 5V13C10 14.1 10.9 15 12 15C13.1 15 14 14.1 14 13V5C14 3.9 13.1 3 12 3Z"
        fill="#D97706"
      />
    </g>
    <defs>
      <clipPath id="clip0_1585_2968">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Warning.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
};

Warning.defaultProps = {
  className: '',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: 'none',
  stroke: '#D97706',
  strokeWidth: '1.5',
};

export default Warning;
