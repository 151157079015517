import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthRoutes, CoreRoutes, SettingRoutes } from './routes';
import BasicLayout from './layouts/BasicLayout';
import AuthLayout from './layouts/AuthLayout';
import NotFound from './pages/NotFound';
import AssetsComponent from './pages/AssetsComponent';
import { Preloader } from './components/atoms';

const App = () => (
  <Routes>
    <Route path="*" element={<NotFound />} />

    {process.env.NODE_ENV !== 'production' && (
      <Route path="/assets" element={<AssetsComponent />} />
    )}

    <Route path="/" element={<BasicLayout />}>
      {CoreRoutes.map((route) => {
        const { component: Component, path, exact, title } = route;
        return (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={
              <React.Suspense fallback={<Preloader />}>
                <Component title={title} />
              </React.Suspense>
            }
          />
        );
      })}
    </Route>

    <Route path="/" element={<BasicLayout />}>
      {SettingRoutes.map((route) => {
        const { component: Component, path, exact, title } = route;
        return (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={
              <React.Suspense fallback={<Preloader />}>
                <Component title={title} />
              </React.Suspense>
            }
          />
        );
      })}
    </Route>

    <Route path="/" element={<AuthLayout />}>
      {AuthRoutes.map((route) => {
        const { component: Component, path, exact } = route;
        return (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={
              <React.Suspense fallback={<Preloader />}>
                <Component />
              </React.Suspense>
            }
          />
        );
      })}
    </Route>
  </Routes>
);

export default App;
