import React, {useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import classNames from 'classnames';
import MUSI from '../components/atoms/Icon/MUSI';
import getWindowSize from "../utils/windowHeight";

const AuthLayout = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div>
            {windowSize.innerWidth > 1024 ?
                <div className="flex justify-between">
                    <div className={classNames('w-full h-screen bg-gradient-to-b from-[#3267E1] to-[#64CCD0]')}>
                        <div
                            className={classNames(
                                "w-full h-screen flex items-center justify-center bg-no-repeat  bg-[position:bottom_left,_top_right] bg-[url('/src/assets/images/login/bl.png'),_url('/src/assets/images/login/tr.png')]",
                            )}
                        >
                            <div className={classNames('lg:w-[1090px] lg:h-[688px] bg-white rounded-lg flex')}>
                                <div
                                    className={classNames(
                                        'flex flex-col bg-login_left lg:w-[644px] lg:h-[688px] bg-[#2853B2] rounded-l-lg',
                                    )}
                                >
                                    <div className={classNames('px-8 mt-8')}>
                                        <MUSI className="h-9 fill-white"/>
                                    </div>
                                    <div className="flex flex-grow items-center justify-end pb-[120px] pr-10">
                                        <div>
                                            <p className="text-4xl font-bold capitalize text-white mb-4 text-right">Welcome</p>
                                            <p className="font-bold text-md text-white text-right">Sign in MUSI Digital School</p>
                                        </div>
                                    </div>
                                </div>
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </div> : <Outlet/>
            }
        </div>
    )
};

export default AuthLayout;
