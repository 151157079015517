import React from 'react';
import {useRecoilValue} from "recoil";

import Device from "../../../store/recoil/device";
import HeaderDesktop from "./headerDesktop";
import HeaderMobile from "./headerMobile";

const Header = (props) => {
  const deviceMobile = useRecoilValue(Device);

  if (deviceMobile) {
    return <HeaderMobile {...props} />
  }

  return <HeaderDesktop {...props} />
};

export default Header;
