import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';

const Sidebar = ({children}) => (
  <aside className="w-68 bg-primary-color dark:bg-gray-800">{children}</aside>
);

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

const SidebarLogo = ({logo: Logo}) => (
  <Link to="/">
    <Logo className="px-6 mt-8 h-9 fill-white"/>
  </Link>
);

SidebarLogo.propTypes = {
  logo: PropTypes.func.isRequired,
};

const SidebarItems = ({children}) => (
  <div className="py-12 px-6 min-h-screen">{children}</div>
);

SidebarItems.propTypes = {
  children: PropTypes.node.isRequired,
};

const SidebarItemGroup = ({children, label}) => (
  <ul
    className="space-y-4 pt-8 mt-8 border-t border-[#2f67b6] dark:border-gray-700 first:mt-0 first:border-t-0 first:pt-0">
    <h3 className="text-frosted-mini-egg font-bold text-xs tracking-wider mb-3">{label}</h3>
    {children}
  </ul>
);

SidebarItemGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const SidebarItem = ({children, icon: Icon, to}) => {
  const location = useLocation();

  return (
    <li>
      <Link
        to={to}
        className={classNames(
          'relative flex items-center text-sm font-medium tracking-wide rounded-lg dark:text-white dark:hover:bg-gray-700',
          Icon === null && 'pl-7',
        )}
      >
        {Icon && (
          <span className="inline-flex items-center justify-center w-7 h-7 bg-noonday-sky rounded-xl">
            <Icon
              className="w-4 h-4 text-cloudy-blue transition duration-75 dark:text-gray-400 dark:group-hover:text-white"/>
          </span>
        )}
        <span
          className={classNames(
            'ml-3',
            location.pathname === to ? 'text-white' : 'text-frosted-mini-egg',
          )}
        >
          {children}
        </span>
        {location.pathname === to && <span className="absolute -right-3 w-0.5 h-full bg-white"/>}
      </Link>
    </li>
  );
};

SidebarItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.func,
  to: PropTypes.string.isRequired,
};

SidebarItem.defaultProps = {
  icon: null,
};

const SidebarCollapse = ({children, icon: Icon, label}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <li>
      <button
        type="button"
        onClick={handleCollapseToggle}
        className="flex items-center w-full text-sm font-medium tracking-wide text-frosted-mini-egg rounded-lg transition duration-75 group dark:text-white dark:hover:bg-gray-700"
      >
        {Icon && (
          <span className="inline-flex items-center justify-center w-7 h-7 bg-noonday-sky rounded-xl">
            <Icon
              className="w-4 h-4 text-cloudy-blue transition duration-75 dark:text-gray-400 dark:group-hover:text-white"/>
          </span>
        )}
        <span className="flex-1 ml-3 text-left whitespace-nowrap">{label}</span>
        <svg
          className={classNames(
            'w-6 h-6 transition-transform transform duration-300 ease-out',
            isCollapsed ? 'rotate-[270deg]' : 'rotate-0',
          )}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <ul className={classNames('pt-4 space-y-4 pl-4', isCollapsed && 'hidden')}>{children}</ul>
    </li>
  );
};

SidebarCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

Sidebar.Logo = SidebarLogo;
Sidebar.Items = SidebarItems;
Sidebar.ItemGroup = SidebarItemGroup;
Sidebar.Item = SidebarItem;
Sidebar.Collapse = SidebarCollapse;

export default Sidebar;
