import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '../../atoms/index';
import Success from '../../atoms/Icon/Success';
import Error from '../../atoms/Icon/Error';
import Warning from '../../atoms/Icon/Warning';

const Alert = ({ show, type, children, onConfirm, title }) => {
  let icon;
  switch (type) {
    case 'success':
      icon = <Success strokeWidth="3" width="32" height="32" />;
      break;
    case 'error':
      icon = <Error strokeWidth="3" width="32" height="32" />;
      break;
    case 'warning':
      icon = <Warning width="32" height="32" />;
      break;
    default:
      break;
  }
  return (
    <div
      className={`flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 bg-black/50 transition-all duration-300 ease-in-out ${
        show ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}
      role="presentation"
    >
      <div
        className={classNames(
          'px-6 py-6 rounded-md w-96 bg-white translate-y-48 transition-all duration-300 ease-in-out shadow',
          show && 'translate-y-0',
        )}
      >
        <div className="flex-col space-y-4">
          <div className="flex space-x-3 items-center">
            {icon}
            <p className="text-xl font-bold">{title}</p>
          </div>
          {children}
          <div className="flex space-x-3 justify-end items-center">
            <Button variant="soft" onClick={onConfirm} widthVariant="auto">
              Okay
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default Alert;
