import { lazy } from 'react';

const settingRoutes = [
  {
    path: '/setting/class-management',
    component: lazy(() => import('../pages/Home')),
    title: 'Class Management',
    exact: true,
    type: 'setting',
  },
  {
    path: '/setting/subject-management',
    component: lazy(() => import('../pages/SubjectManagement')),
    title: 'Subject Management',
    exact: true,
    type: 'setting',
  },

  // start : USER MANAGEMENT
  {
    path: '/setting/user-management',
    component: lazy(() => import('../pages/UserManagement')),
    title: 'User Management',
    exact: true,
    type: 'setting',
  },
  {
    path: '/setting/user-management/:userId',
    component: lazy(() => import('../pages/UserManagement/detail')),
    title: 'Detail User',
    exact: true,
    type: 'setting',
  },
  {
    path: '/setting/user-management/create',
    component: lazy(() => import('../pages/UserManagement/create')),
    title: 'Create User',
    exact: true,
    type: 'setting',
  },
  // end : USER MANAGEMENT

  // start : MAJOR MANAGEMENT
  {
    path: '/setting/major-management/',
    component: lazy(() => import('../pages/MajorManagement')),
    title: 'Major Management',
    exact: true,
    type: 'setting',
  },
  // end : MAJOR MANAGEMENT
];

export default settingRoutes;
