import { lazy } from 'react';

const authRoutes = [
  {
    path: '/login',
    component: lazy(() => import('../pages/Auth/Login')),
    title: 'Login',
    exact: true,
    type: 'auth',
  },
  {
    path: '/forget-password',
    component: lazy(() => import('../pages/Auth/ForgetPassword')),
    title: 'Forget Password',
    exact: true,
    type: 'auth',
  },
  {
    path: '/instruction-sent',
    component: lazy(() => import('../pages/Auth/InstructionSent')),
    title: 'Forget Password',
    exact: true,
    type: 'auth',
  },
];

export default authRoutes;
