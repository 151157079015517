/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const dashboardRoutes = [
  {
    path: '/',
    title: 'Home',
    exact: true,
    type: 'core',
  },
  {
    path: '/setting/user-management',
    title: 'User Management',
    exact: true,
    type: 'setting',
  },
  {
    path: '/setting/class-management',
    title: 'Class Management',
    exact: true,
    type: 'setting',
  },
  {
    path: '/setting/subject-management',
    title: 'Subject Management',
    exact: true,
    type: 'setting',
  },
];

const SidebarMolecules = ({ sidebarType, className }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarSections = [];

  switch (sidebarType) {
    case 'admin': {
      const arrNavCore = dashboardRoutes.filter((val) => val.type === 'core');
      const arrSettingNav = dashboardRoutes.filter((val) => val.type === 'setting');

      sidebarSections.push({
        title: 'CORE',
        sidebarItems: arrNavCore,
      });
      sidebarSections.push({
        title: 'SETTING',
        sidebarItems: arrSettingNav,
      });
      break;
    }
    case 'teacher':
      break;
    case 'siswa':
      break;
    case 'backoffice':
      break;
    default:
      break;
  }

  return (
    <div className={className}>
      {sidebarSections.map((section, i, row) => (
        <div key={i}>
          <div>
            <div>
              <h3 className="font-bold text-xs tracking-wider">{section.title}</h3>
              <ul className="mt-5">
                {section.sidebarItems.map((item, u) => (
                  <li
                    role="menuitem"
                    className={classNames(
                      'flex items-center transition-colors duration-300 mb-4 hover:cursor-pointer hover:text-white',
                      location.pathname === item.path &&
                        'border-r-2 -mr-6 text-white border-solid border-white',
                    )}
                    key={u}
                    onClickCapture={() => navigate(item.path)}
                  >
                    <div className="flex items-center justify-center w-7 h-7 bg-noonday-sky rounded-xl mr-3">
                      <svg
                        className="w-4 h-4"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.66699 5.66667L7.00033 1L12.3337 5.66667L12.3337 12.3333H9.00033V9.66667C9.00033 9.13623 8.78962 8.62753 8.41454 8.25245C8.03947 7.87738 7.53076 7.66667 7.00033 7.66667C6.4699 7.66667 5.96119 7.87738 5.58612 8.25245C5.21104 8.62752 5.00033 9.13623 5.00033 9.66667V12.3333H1.667L1.66699 5.66667Z"
                          stroke="#C2D7FF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="font-medium text-sm tracking-wide">{item.title}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={row.length - 1 !== i ? 'w-full h-px my-8 bg-[#2f67b6]' : ''} />
        </div>
      ))}
    </div>
  );
};

SidebarMolecules.propTypes = {
  sidebarType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SidebarMolecules.defaultProps = {
  className: '',
};

export default SidebarMolecules;
