import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Badge = ({ variant, size, children }) => (
  <div
    className={classNames(
      `inline-flex rounded-full uppercase`,
      variant === 'ACTIVE' && 'bg-grelkala text-traffic-green',
      variant === 'INACTIVE' && 'bg-pony-cupcake-glitter-princess text-youtube-red',
      variant === 'SUSPEND' && 'bg-banana-and-sunshine text-ketchup-and-mustard-mix',
      variant === 'TRIAL' && 'bg-blue-sky-seashell text-flooded-marriage',
      size === 'small' && 'py-1 px-3 font-bold text-xs',
      size === 'medium' && 'py-2 px-4 font-bold text-sm',
    )}
  >
    {children}
  </div>
);

Badge.propTypes = {
  variant: PropTypes.oneOf(['ACTIVE', 'INACTIVE', 'SUSPEND', 'TRIAL']).isRequired,
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
};

export default Badge;
