import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Select = ({
  id,
  name,
  placeholder,
  className,
  size,
  value,
  onChange,
  labelTitle,
  items,
  required,
  isSubmitted,
}) => {
  const renderRequired = () => {
    if (required) {
      return <span className="text-youtube-red">*</span>;
    }
    return '';
  };

  const renderRequiredField = () => {
    if (isSubmitted && required && (value == null || value === '')) {
      return <p className="text-xs mt-2 text-youtube-red">Field {labelTitle} is Required</p>;
    }
    return '';
  };

  const optionList =
    items.length > 0 &&
    items.map(
      (item) => (
        <option selected={item.selected} disabled={item.disabled} key={item.id} value={item.id}>
          {item.label}
        </option>
      ),
      this,
    );

  return (
    <div className="w-full block">
      {labelTitle !== null ? (
        <label htmlFor={id}>
          {labelTitle} {renderRequired()}
        </label>
      ) : null}
      <select
        id={id}
        name={name}
        placeholder={placeholder}
        className={classNames(
          'appearance-none w-full box-border border border-solid border-[#d1d5db] rounded bg-etherals outline-none text-[#4b5563] py-2 px-3 bg-chevron-down-small bg-right-center bg-no-repeat',
          'placeholder:font-medium focus:border-[#60a5fa] focus:ring focus:ring-[#dbeafe]',
          className,
          size === 'small' && 'py-2 px-3 text-sm',
          size === 'big' && 'py-3 px-4 text-base',
          isSubmitted &&
            required &&
            (value == null || value === '') &&
            'text-youtube-red border-youtube-red',
          className,
        )}
        value={value}
        onChange={onChange}
      >
        {optionList}
      </select>
      {renderRequiredField()}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      selected: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  required: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
};

export default Select;
