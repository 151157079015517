import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import MUSI from "../../atoms/Icon/MUSI";

const HeaderMobile = () => (
  <div className="w-full bg-primary-color py-5 px-4 min-[400px]:px-6">
    <div className="flex justify-between">
      <Link to="/">
        <MUSI className="h-9 fill-white"/>
      </Link>

      <div className="flex">
        <button
          aria-label="Show"
          type="button"
          className={classNames(
            'w-10 h-10 rounded-full bg-profile-pic bg-cover transition duration-300 border border-solid border-blue-sky-seashell ml-7',
            'hover:cursor-pointer hover:opacity-50',
          )}
        />
      </div>
    </div>
  </div>
);

export default HeaderMobile;
