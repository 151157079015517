import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ModalHead = ({ children }) => <div className="px-4 mt-[32px]">{children}</div>;

ModalHead.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalBody = ({ children }) => (
  <div className="px-4 py-6 border-solid border-gray-300">{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalFooter = ({ children }) => <div className="px-4 py-6 bg-[#EFF6FF]">{children}</div>;

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

const Modal = ({ show, onClose, children, size }) => {
  const onEscapeKeyDown = (e) => {
    if (e.charCode === 27 || e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', onEscapeKeyDown);
    return function cleanUp() {
      document.body.removeEventListener('keydown', onEscapeKeyDown);
    };
  });

  return (
    <div
      className={classNames(
        'fixed left-0 right-0 top-0 bottom-0 bg-black/50 flex items-center justify-center transition-all duration-300 ease-in-out pointer-events-none',
        show ? 'opacity-100 pointer-events-auto' : 'opacity-0',
      )}
      role="presentation"
    >
      <div
        className={classNames(
          'rounded-md w-96 bg-white translate-y-48 transition-all duration-300 ease-in-out shadow',
          size,
          show && 'translate-y-0',
        )}
        role="presentation"
        onKeyDown={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
};

Modal.defaultProps = {
  size: '',
};

Modal.Head = ModalHead;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
