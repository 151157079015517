import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Table = ({ children }) => (
  <table className="table-auto w-full text-sm font-medium text-left font-inter text-gray-500 dark:text-gray-400">
    {children}
  </table>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
};

const TableHead = ({ children }) => (
  <thead className="text-xs uppercase text-gray-600 bg-at-a-glance dark:bg-gray-700 dark:text-gray-400">
    <tr>{children}</tr>
  </thead>
);

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
};

const TableHeadCell = ({ children, className, withCheckbox }) => (
  <th scope="col" className={classNames('py-3 px-6', withCheckbox && 'w-0 !pl-6 pr-4', className)}>
    {children}
  </th>
);

TableHeadCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  withCheckbox: PropTypes.bool,
};

TableHeadCell.defaultProps = {
  className: '',
  withCheckbox: false,
};

const TableBody = ({ children }) => <tbody>{children}</tbody>;

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

const TableRow = ({ checked, disabled, children }) => (
  <tr
    className={classNames(
      'bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600',
      disabled && 'bg-moon pointer-events-none',
      checked && 'bg-frosted-mini-egg hover:bg-frosted-mini-egg',
    )}
  >
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

TableRow.defaultProps = {
  disabled: false,
  checked: false,
};

const TableCell = ({ checked, children, className, withCheckbox }) => (
  <td
    className={classNames(
      'py-4 px-6',
      withCheckbox && 'w-0 !pl-6 pr-4',
      checked && 'bg-primary-color',
      className,
    )}
  >
    {children}
  </td>
);

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  withCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
};

TableCell.defaultProps = {
  className: '',
  withCheckbox: false,
  checked: false,
};

const TableLoading = ({ rowCount }) => (
  <>
    {Array.from(Array(rowCount).keys()).map((i) => (
      <tr key={i}>
        <td colSpan="100%" className="animate-pulse">
          <div className="h-6 bg-primary-color rounded-full my-6" />
        </td>
      </tr>
    ))}
  </>
);

TableLoading.propTypes = {
  rowCount: PropTypes.number,
};

TableLoading.defaultProps = {
  rowCount: 5,
};

Table.Head = TableHead;
Table.HeadCell = TableHeadCell;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Loading = TableLoading;

export default Table;
