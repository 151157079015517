import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Radio = ({ selected, onChange, label, value, className, disabled }) => (
  <div
    className={classNames('flex items-center cursor-pointer select-none', className)}
    onKeyDown={onChange}
    role="radio"
    aria-checked={selected}
    aria-disabled={disabled}
    tabIndex={value === selected ? '0' : '1'}
  >
    <div
      className={classNames(
        'w-5 h-5 border-2 border-solid border-primary-color rounded-full flex justify-center items-center transition-all duration-100 ease-linear',
        value === selected && 'bg-primary-color',
        disabled && 'bg-moon border-fantastic-purple-blue',
      )}
    >
      <div
        className={classNames(
          'w-0 h-0 rounded-full bg-white transition-all duration-100 ease-linear',
          value === selected && 'w-2 h-2',
        )}
      />
    </div>
    <div className="ml-2 text-primary-color pr-2">{label}</div>
  </div>
);

Radio.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  selected: 0,
  disabled: false,
};

export default Radio;
