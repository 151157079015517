import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ children }) => (
  <div className="flex items-center text-sm font-bold">{children}</div>
);

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};

const BreadcrumbItem = ({ children, to }) => (
  <Link
    className="flex group items-center capitalize text-decoration-none text-gray-600 last:pointer-events-none first:pointer-events-none"
    to={to}
  >
    <span className="group-last:text-flooded-marriage">{children}</span>

    <svg
      className="stroke-flooded-marriage stroke-2 w-6 h-6 mx-2 group-last:hidden"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 17L15 12M10 7L12.5 9.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </Link>
);

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
