import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePagination, DOTS } from '../../../hooks/usePagination';

const Pagination = ({ currentPage, totalPages, totalItems, currentLimit, onPageChange }) => {
  const paginationRanges = usePagination({
    currentPage,
    totalItems,
    totalPages,
  });

  return (
    <nav className="flex justify-between items-center pt-4">
      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing{' '}
        <span className="font-semibold text-gray-900 dark:text-white">
          {currentLimit * currentPage - currentLimit + 1}-
          {currentPage !== totalPages ? currentLimit * currentPage : totalItems}
        </span>{' '}
        of <span className="font-semibold text-gray-900 dark:text-white">{totalItems}</span> Items
      </span>
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button
            onClick={() => {
              onPageChange(currentPage !== 1 ? currentPage - 1 : currentPage);
            }}
            type="button"
            className="inline-flex py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="mr-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Previous
          </button>
        </li>
        {paginationRanges.map((range, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i}>
            <button
              onClick={() => {
                onPageChange(range);
              }}
              type="button"
              className={classNames(
                'py-2 px-3 leading-tight border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
                range === currentPage
                  ? 'bg-blue-50 border-blue-300 text-blue-600 hover:bg-blue-100 hover:text-blue-700'
                  : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700',
              )}
              disabled={range === DOTS || range === currentPage}
            >
              {range}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => {
              onPageChange(currentPage < totalPages ? currentPage + 1 : currentPage);
            }}
            type="button"
            className="inline-flex py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
            <svg
              aria-hidden="true"
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </li>
      </ul>
      <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
        Page <span className="font-semibold text-gray-900 dark:text-white">{currentPage}</span> of{' '}
        <span className="font-semibold text-gray-900 dark:text-white">{totalPages}</span>
      </span>
    </nav>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  currentLimit: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
