import React from 'react';
import {Sidebar} from '../../components/atoms';
import MUSI from '../../components/atoms/Icon/MUSI';

import sidebarUser from "./sidebarUser";

const SidebarLayout = () => {
  const sidebar = sidebarUser;

  return (
    <Sidebar>
      <Sidebar.Logo logo={MUSI}/>
      <Sidebar.Items>
        {sidebar?.map((itemGroup, index) => (
            <Sidebar.ItemGroup key={`${index + 1}, itemGroup`} label={itemGroup.group}>
              {
                itemGroup.items?.map((item) => {
                  if (item?.collapse_items && item?.collapse_items.length > 0) {
                    return (
                      <Sidebar.Collapse key={`${index + 1}, ${item.label}`} icon={item.icon} label={item.label} >
                        {item?.collapse_items?.map((collapseItem) => (
                          <Sidebar.Item key={`${index + 1}, ${collapseItem.label}`} icon={collapseItem.icon} to={collapseItem.to}>{collapseItem.label}</Sidebar.Item>
                        ))}
                      </Sidebar.Collapse>
                    )
                  }

                  return (
                    <Sidebar.Item key={`${index + 1}, ${item.label}`} icon={item.icon} to={item.to}>
                      {item.label}
                    </Sidebar.Item>
                  )
                })
              }
            </Sidebar.ItemGroup>
          )
        )}
      </Sidebar.Items>
    </Sidebar>
  )
};

export default SidebarLayout;
