import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  type,
  id,
  placeholder,
  className,
  size,
  value,
  onChange,
  autoComplete,
  labelTitle,
  required,
  isSubmitted,
}) => {
  const renderRequired = () => {
    if (required) {
      return <span className="text-youtube-red">*</span>;
    }
    return '';
  };

  const renderRequiredField = () => {
    if (isSubmitted && required && (value == null || value === '')) {
      return (
        <p style={{ marginTop: '4px' }} className="text-xs mt-2 text-youtube-red">
          Field {labelTitle} is Required
        </p>
      );
    }
    return '';
  };

  if (type === 'textarea') {
    return (
      <div className="w-full block">
        {labelTitle !== null ? (
          <label htmlFor={id} className="text-sm text-griseux font-medium">
            {labelTitle} {renderRequired()}
          </label>
        ) : null}
        <textarea
          id={id}
          className={classNames(
            'w-full box-border border border-solid border-[#d1d5db] rounded bg-etherals outline-none text-[#4b5563] py-2 px-3',
            'placeholder:font-medium focus:border-[#60a5fa] focus:ring focus:ring-[#dbeafe]',
            size === 'small' && 'py-2 px-3 text-sm',
            size === 'big' && 'py-3 px-4 text-base',
            isSubmitted &&
              required &&
              (value == null || value === '') &&
              'text-youtube-red border-youtube-red',
          )}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          rows="5"
        />
      </div>
    );
  }

  return (
    <div className="w-full block">
      {labelTitle !== null ? (
        <label htmlFor={id} className="text-sm text-griseux font-medium">
          {labelTitle} {renderRequired()}
        </label>
      ) : null}
      <input
        type={type}
        id={id}
        placeholder={placeholder}
        className={classNames(
          'w-full box-border border border-solid border-[#d1d5db] rounded bg-etherals outline-none text-[#4b5563] py-2 px-3',
          'placeholder:font-medium focus:border-[#60a5fa] focus:ring focus:ring-[#dbeafe]',
          className,
          size === 'small' && 'py-2 px-3 text-sm',
          size === 'big' && 'py-3 px-4 text-base',
          isSubmitted &&
            required &&
            (value == null || value === '') &&
            'text-youtube-red border-youtube-red',
        )}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
      />
      {renderRequiredField()}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  labelTitle: PropTypes.string.isRequired,
  required: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

Input.defaultProps = {
  type: '',
  placeholder: '',
  className: '',
  size: '',
  value: '',
  onChange: () => {},
  autoComplete: '',
  required: false,
  isSubmitted: false,
};

export default Input;
