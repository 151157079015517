import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Toggle = ({ checked, onChange, disabled }) => (
  <div className="flex items-center">
    <label htmlFor="switch" className="relative inline-block w-15 h-9 mr-3">
      <input
        id="switch"
        className="opacity-0 w-0 h-0"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span
        className={classNames(
          'w-full h-full absolute cursor-pointer top-0 left-0 right-0 bottom-0 text-gray-300 rounded-3xl border-2 border-solid border-primary-color',
          "before:absolute before:content-[''] before:rounded-full before:h-6 before:w-6 before:left-1 before:bottom-1 before:bg-primary-color",
          'before:bg-[length:14px_14px] before:bg-center-center before:bg-no-repeat before:transition before:duration-300',
          checked &&
            'bg-primary-color before:bg-white before:translate-x-6 before:bg-[length:14px_14px] before:bg-center before:bg-no-repeat bg-primary-color',
          disabled && 'pointer-events-none bg-moon before:bg-fantastic-purple-blue border-moon',
        )}
      />
    </label>
  </div>
);

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Toggle.defaultProps = {
  disabled: false,
};

export default Toggle;
