import Home1Duotone from "../../components/atoms/Icon/Duotone/Home1";
import UsersDuotone from "../../components/atoms/Icon/Duotone/Users";
import DocumentFilled from "../../components/atoms/Icon/DocumentFilled";

const sidebarUser = [
  {
    group : "CORE",
    items : [
      {
        label : "Home",
        icon : Home1Duotone,
        to : "/",
        collapse_items: []
      },
    ]
  },
  {
    group : "LMS",
    items : [
      {
        label : "XI RPL 1",
        icon : UsersDuotone,
        to : "/",
        collapse_items: [
          {
            label : "Matematika",
            icon : DocumentFilled,
            to : "/"
          },
          {
            label : "Bahasa Indonesia",
            icon : DocumentFilled,
            to : "/"
          },
        ]
      }
    ]
  }
];

export default sidebarUser;