import React from 'react';
import PropTypes from 'prop-types';

const UsersDuotone = ({className, fill}) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99992 7.33333C8.47268 7.33333 9.66659 6.13943 9.66659 4.66667C9.66659 3.19391 8.47268 2 6.99992 2C5.52716 2 4.33325 3.19391 4.33325 4.66667C4.33325 6.13943 5.52716 7.33333 6.99992 7.33333Z"
      fill={fill}/>
    <path
      d="M16.3334 14V12.6667C16.3334 11.1939 15.1395 10 13.6667 10H13.3334M11.0001 7.33333C12.4728 7.33333 13.6667 6.13943 13.6667 4.66667C13.6667 3.19391 12.4728 2 11.0001 2M12.3334 14V12.6667C12.3334 11.1939 11.1395 10 9.66675 10H4.33341C2.86066 10 1.66675 11.1939 1.66675 12.6667V14M9.66675 4.66667C9.66675 6.13943 8.47284 7.33333 7.00008 7.33333C5.52732 7.33333 4.33341 6.13943 4.33341 4.66667C4.33341 3.19391 5.52732 2 7.00008 2C8.47284 2 9.66675 3.19391 9.66675 4.66667Z"
      stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

UsersDuotone.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

UsersDuotone.defaultProps = {
  className: '',
  fill: '#FDFDFD',
};

export default UsersDuotone;
