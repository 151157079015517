import React from 'react';
import PropTypes from 'prop-types';

const SideContent = ({ children, isActive, className, onClick }) => (
  <aside
    onClickCapture={onClick}
    className={`mt-4 pl-4 border-l-4 border-primary-color cursor-pointer ${
      isActive
        ? 'border-l-4 border-primary-color text-primary-color'
        : 'border-l-4 border-transparent text-black'
    } ${className}`}
  >
    {children}
  </aside>
);

SideContent.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SideContent.defaultProps = {
  className: '',
  isActive: false,
  onClick: () => {},
};

const SideContentTitle = ({ children }) => (
  <span className="font-medium text-xl block">{children}</span>
);

SideContentTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

const SideContentDescription = ({ children }) => (
  <span className="font-normal text-lg block">{children}</span>
);

SideContentDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

SideContent.Title = SideContentTitle;
SideContent.Description = SideContentDescription;

export default SideContent;
