import React from 'react';
import PropTypes from 'prop-types';

const DocumentFilled = ({className, fill}) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33325 2V1.25C2.91904 1.25 2.58325 1.58579 2.58325 2H3.33325ZM12.6666 14V14.75C13.0808 14.75 13.4166 14.4142 13.4166 14H12.6666ZM3.33325 14H2.58325C2.58325 14.4142 2.91904 14.75 3.33325 14.75V14ZM9.33325 2L9.86358 1.46967C9.72293 1.32902 9.53216 1.25 9.33325 1.25V2ZM12.6666 5.33333H13.4166C13.4166 5.13442 13.3376 4.94366 13.1969 4.803L12.6666 5.33333ZM5.33325 10.5833C4.91904 10.5833 4.58325 10.9191 4.58325 11.3333C4.58325 11.7475 4.91904 12.0833 5.33325 12.0833V10.5833ZM10.6666 12.0833C11.0808 12.0833 11.4166 11.7475 11.4166 11.3333C11.4166 10.9191 11.0808 10.5833 10.6666 10.5833V12.0833ZM5.33325 7.91667C4.91904 7.91667 4.58325 8.25245 4.58325 8.66667C4.58325 9.08088 4.91904 9.41667 5.33325 9.41667V7.91667ZM9.33325 9.41667C9.74747 9.41667 10.0833 9.08088 10.0833 8.66667C10.0833 8.25245 9.74747 7.91667 9.33325 7.91667V9.41667ZM11.9166 8C11.9166 8.41421 12.2524 8.75 12.6666 8.75C13.0808 8.75 13.4166 8.41421 13.4166 8H11.9166ZM13.4166 10.6667C13.4166 10.2525 13.0808 9.91667 12.6666 9.91667C12.2524 9.91667 11.9166 10.2525 11.9166 10.6667H13.4166ZM2.58325 3.33333C2.58325 3.74755 2.91904 4.08333 3.33325 4.08333C3.74747 4.08333 4.08325 3.74755 4.08325 3.33333H2.58325ZM4.08325 6C4.08325 5.58579 3.74747 5.25 3.33325 5.25C2.91904 5.25 2.58325 5.58579 2.58325 6H4.08325ZM12.6666 13.25H3.33325V14.75H12.6666V13.25ZM3.33325 2.75H9.33325V1.25H3.33325V2.75ZM8.80292 2.53033L12.1363 5.86366L13.1969 4.803L9.86358 1.46967L8.80292 2.53033ZM7.91659 2V4.66667H9.41659V2H7.91659ZM9.99992 6.75H12.6666V5.25H9.99992V6.75ZM7.91659 4.66667C7.91659 5.81726 8.84933 6.75 9.99992 6.75V5.25C9.67775 5.25 9.41659 4.98883 9.41659 4.66667H7.91659ZM5.33325 12.0833H10.6666V10.5833H5.33325V12.0833ZM5.33325 9.41667H9.33325V7.91667H5.33325V9.41667ZM11.9166 5.33333V8H13.4166V5.33333H11.9166ZM11.9166 10.6667V14H13.4166V10.6667H11.9166ZM4.08325 3.33333V2H2.58325V3.33333H4.08325ZM4.08325 14V6H2.58325V14H4.08325Z"
      fill={fill}/>
  </svg>
);

DocumentFilled.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

DocumentFilled.defaultProps = {
  className: '',
  fill: '#FDFDFD',
};

export default DocumentFilled;
