import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  className,
  children,
  widthVariant,
  disabled,
  variant,
  icon,
  onClick,
  submit,
}) => (
  <button
    type={submit ? 'submit' : 'button'}
    disabled={disabled}
    onClick={onClick}
    className={classNames(
      'py-3 px-7 rounded-lg inline-flex items-center focus:ring-4 focus:ring-blue-300',
      'dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800',
      'disabled:bg-primary-color disabled:opacity-50 disabled:cursor-not-allowed',
      widthVariant === 'full' && 'w-full',
      widthVariant === 'auto' && 'w-auto',
      variant === 'default' &&
        'bg-primary-color from-blue-600 via-blue-700 to-blue-800 hover:bg-gradient-to-br',
      variant === 'soft' && 'w-auto bg-[#dbeafe]',
      variant === 'outline' && 'border-2 border-solid border-primary-color bg-white',
      variant === 'shadow' && 'bg-white shadow-md',
      icon && 'py-3 px-6',
      className,
    )}
  >
    {icon}
    <span
      className={classNames(
        'font-medium text-white',
        variant === 'soft' && 'text-[#154890]',
        variant === 'outline' && 'text-primary-color',
        variant === 'shadow' && 'text-griseux',
        icon && 'ml-2',
      )}
    >
      {children}
    </span>
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  widthVariant: PropTypes.oneOf(['full', 'auto']),
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'soft', 'outline', 'shadow']),
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  submit: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  icon: null,
  variant: 'default',
  disabled: false,
  submit: false,
  widthVariant: 'auto',
};

export default Button;
