import React from 'react';
import PropTypes from 'prop-types';

const MUSI = ({ className }) => (
  <svg
    className={className}
    fill="currentColor"
    viewBox="0 0 76 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 29.8817V11.2464C4.44956e-05 8.47056 4.92091 8.28233 5.10311 10.3052C5.10311 8.28233 14.2159 7.71762 15.8561 11.2464C20.048 6.39998 30.4364 7.52941 30.4364 17.2699V22.7287C30.4364 27.6706 40.0959 27.8588 40.0959 22.7287V12.7522C40.0959 9.2235 45.7458 9.2235 45.7458 12.7522V29.317C45.7458 31.8117 42.2829 32.8935 40.2782 30.4464C33.9326 34.1275 24.9894 30.4424 24.9688 23.6699L24.7866 19.9529C24.7866 15.247 18.0432 15.6235 18.0432 19.9529V29.9997C18.0432 32.4945 12.3933 32.4945 12.3933 29.9997V24.7994C12.3933 20.3294 5.46762 20.3294 5.46762 24.7994V29.8817C5.46762 32.5647 0 32.5647 0 29.8817Z" />
    <path d="M31.8945 11.1059C31.8945 9.13065 33.4449 7.52941 35.3573 7.52941C37.2698 7.52941 38.8202 9.13065 38.8202 11.1059V22.0235C38.8202 23.9988 37.2698 25.6 35.3573 25.6C33.4449 25.6 31.8945 23.9988 31.8945 22.0235V11.1059Z" />
    <path d="M69.4389 11.4824C69.4389 9.71504 70.826 8.28235 72.5372 8.28235C74.2483 8.28235 75.6355 9.71504 75.6355 11.4824V28.8C75.6355 30.5673 74.2483 32 72.5372 32C70.826 32 69.4389 30.5673 69.4389 28.8V11.4824Z" />
    <path d="M76 3.38824C76 5.25951 74.5312 6.77647 72.7194 6.77647C70.9076 6.77647 69.4389 5.25951 69.4389 3.38824C69.4389 1.51696 70.9076 0 72.7194 0C74.5312 0 76 1.51696 76 3.38824Z" />
    <path d="M66.3406 23.7177C66.3406 19.7647 60.8729 18.1557 59.2327 17.5059C57.5925 16.8561 53.6971 16.3509 53.9473 15.0588C54.3118 13.1765 58.3214 13.5906 59.5971 14.1176C61.8753 15.0588 61.4593 16.7095 64.0528 15.8166C64.9876 15.4947 65.976 15.0588 65.976 13.9294C65.976 11.1059 62.6592 8.85781 58.6859 8.47061H55.952C52.307 8.65882 48.6619 10.5412 48.6619 15.2508C48.6619 19.9567 53.4323 21.5031 55.0408 21.8353C55.952 22.0235 60.8729 22.7765 60.8729 24.6588C60.8729 26.9177 55.466 26.5412 53.9473 25.6C52.4285 24.6588 52.4892 23.7177 50.3023 23.7177C50.3023 23.7177 47.7506 23.7177 47.7506 27.2941C47.7506 28.6118 51.7603 31.4353 56.1344 31.6236H58.3214C63.789 31.6236 66.3406 27.8588 66.3406 25.0353V23.7177Z" />
  </svg>
);

MUSI.propTypes = {
  className: PropTypes.string,
};

MUSI.defaultProps = {
  className: '',
};

export default MUSI;
