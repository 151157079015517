import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Counter } from '../../atoms';

const HorizontalTab = ({ children }) => (
  <div className="border-b border-gray-200 dark:border-gray-700">
    <ul className="flex flex-wrap -mb-px text-base font-medium text-center text-gray-500 dark:text-gray-400">
      {children}
    </ul>
  </div>
);

HorizontalTab.propTypes = {
  children: PropTypes.node.isRequired,
};

const HorizontalTabItem = ({ onClick, active, variant, label, count }) => (
  <li onClickCapture={onClick} className="mr-2">
    <button
      type="button"
      className={classNames(
        'inline-flex items-center p-3 rounded-t-lg group text-gray-500',
        active
          ? 'border-b-2 text-primary-color border-primary-color font-medium'
          : 'hover:border-b-2 hover:border-gray-300 dark:hover:text-gray-200',
      )}
    >
      <svg
        aria-hidden="true"
        className={classNames(
          'mr-3 w-6 h-6 text-gray-400',
          active
            ? 'text-primary-color'
            : 'group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300',
        )}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
          clipRule="evenodd"
        />
      </svg>
      <span className="mr-3">{label}</span>
      {variant === 'counter' && <Counter active={active} count={count} />}
    </button>
  </li>
);

HorizontalTabItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['counter']).isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

HorizontalTab.Item = HorizontalTabItem;

export default HorizontalTab;
