import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BubbleBox = ({ disabled, children }) => {
  const [isBubbleBoxActive, setIsBubbleBoxActive] = useState(false);

  return (
    <div className="flex justify-end relative">
      <button
        disabled={disabled}
        type="button"
        className={classNames(
          'z-10 flex justify-center items-center w-9 h-9 transition-all duration-100 ease-linear',
          'hover:rounded-lg hover:bg-[#ecf1f6]',
          disabled && 'pointer-events-none',
        )}
        onClick={() => setIsBubbleBoxActive(!isBubbleBoxActive)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4B5563"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="1" />
          <circle cx="19" cy="12" r="1" />
          <circle cx="5" cy="12" r="1" />
        </svg>
      </button>
      <div>
        {isBubbleBoxActive && (
          <div
            role="presentation"
            onClick={() => setIsBubbleBoxActive(!isBubbleBoxActive)}
            className="z-10 fixed top-0 right-0 left-0 bottom-0 transition-all duration-100 ease-linear"
          />
        )}

        <div
          className={classNames(
            'w-44 z-30 absolute border border-solid border-[#d1d5db] bg-white shadow divide-y divide-gray-100 rounded right-0 top-10 py-2',
            isBubbleBoxActive ? 'flex flex-col' : 'hidden',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

BubbleBox.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

BubbleBox.defaultProps = {
  disabled: false,
};

const BubbleBoxItem = ({ children, onClick }) => (
  <button
    type="button"
    className="flex items-center py-3 px-4 hover:bg-at-a-glance"
    onClick={onClick}
  >
    {children}
  </button>
);

BubbleBoxItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const BubbleBoxLabel = ({ children }) => (
  <span className="ml-3 text-gray-600 text-left text-sm font-medium">{children}</span>
);

BubbleBoxLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

BubbleBox.Item = BubbleBoxItem;
BubbleBox.Label = BubbleBoxLabel;

export default BubbleBox;
