import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Counter = ({ count, active }) => {
  const finalNumber = count > 999 ? `${999}+` : count;

  return (
    <div
      className={classNames(
        'p-1 rounded-[4px] bg-frosted-mini-egg',
        active ? 'text-primary-color' : 'text-griseux',
      )}
    >
      <p className="text-sm font-bold">{finalNumber}</p>
    </div>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Counter;
