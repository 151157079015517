import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = ({ checked, onChange, required, disabled, children }) => (
  <label className="inline-flex items-center">
    <input
      type="checkbox"
      className={classNames(
        'w-4 h-4 rounded border-2 border-primary-color text-primary-color shadow-sm focus:ring focus:ring-offset-0 focus:ring-indigo-300 focus:ring-opacity-50 cursor-pointer',
        disabled && 'cursor-auto',
      )}
      onChange={onChange}
      checked={checked}
      required={required}
      disabled={disabled}
    />
    <span className="text-sm text-griseux font-medium ml-2">{children}</span>
  </label>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Checkbox.defaultProps = {
  checked: false,
  required: false,
  disabled: false,
  children: '',
};

export default Checkbox;
