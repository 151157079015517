import React from 'react';
import PropTypes from 'prop-types';

const Success = ({ className, width, height, viewBox, fill, stroke, strokeWidth }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0001 7L11.5001 15.5M9.0001 18L4 13"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Success.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
};

Success.defaultProps = {
  className: '',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  fill: 'none',
  stroke: '#008450',
  strokeWidth: '1.5',
};

export default Success;
