import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, width, color }) => (
  <object width={width} color={color} type="image/svg+xml" data={name} aria-label={name} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
};

Icon.defaultProps = {
  color : "#001A72"
}

export default Icon;
