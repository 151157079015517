import React from 'react';
import PropTypes from 'prop-types';

const Home1Duotone = ({className, fill}) => (
  <svg
    className={className}
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66675 6.66667L8.00008 2L13.3334 6.66667L13.3334 13.3333H10.0001V10.6667C10.0001 10.1362 9.78937 9.62753 9.4143 9.25245C9.03923 8.87738 8.53052 8.66667 8.00008 8.66667C7.46965 8.66667 6.96094 8.87738 6.58587 9.25245C6.2108 9.62752 6.00008 10.1362 6.00008 10.6667V13.3333H2.66675L2.66675 6.66667Z"
      fill={fill}/>
    <path
      d="M2.66675 6.66667L8.00008 2L13.3334 6.66667L13.3334 13.3333H10.0001V10.6667C10.0001 10.1362 9.78937 9.62753 9.4143 9.25245C9.03923 8.87738 8.53052 8.66667 8.00008 8.66667C7.46965 8.66667 6.96094 8.87738 6.58587 9.25245C6.2108 9.62752 6.00008 10.1362 6.00008 10.6667V13.3333H2.66675L2.66675 6.66667Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Home1Duotone.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Home1Duotone.defaultProps = {
  className: '',
  fill: '#FDFDFD',
};

export default Home1Duotone;
