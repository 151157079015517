import { lazy } from 'react';

const coreRoutes = [
  {
    path: '/',
    component: lazy(() => import('../pages/Home')),
    title: 'Home',
    exact: true,
    type: 'core',
  },
];

export default coreRoutes;
