import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import {useNavigate} from 'react-router-dom';

import HttpConstants from '../../../constants/httpConstants';
import {Breadcrumb} from '../../atoms';

const HeaderDesktop = ({breadcrumbItems}) => {
  const navigate = useNavigate();

  const [showBubbleProfile, setShowBubbleProfile] = useState(false);
  // const [isToggled, setIsToggled] = useState(false);

  const handleLogout = () => {
    Cookies.remove(HttpConstants.Token, {path: ''});
    navigate('/login');
  };
  //
  // useEffect(() => {
  //   if (isToggled) {
  //     localStorage.setItem('color-theme', 'dark');
  //     document.documentElement.classList.add('dark');
  //   } else {
  //     localStorage.setItem('color-theme', '');
  //     document.documentElement.classList.remove('dark');
  //   }
  // }, [isToggled]);

  return (
    <div className="w-full bg-at-a-glance py-5 px-8">
      <div className="flex justify-between">
        <Breadcrumb>
          {breadcrumbItems.map((item, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <Breadcrumb.Item to={item.to} key={key}>
              {item.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <div className="flex">
          {/* <ToggleDarkMode
            checked={isToggled}
            onChange={() => {
              setIsToggled(!isToggled);
            }}
           /> */}
          <button
            aria-label="Show"
            type="button"
            className={classNames(
              'w-10 h-10 rounded-full bg-profile-pic bg-cover transition duration-300 border border-solid border-blue-sky-seashell ml-7',
              'hover:cursor-pointer hover:opacity-50',
            )}
            onClick={() => setShowBubbleProfile(!showBubbleProfile)}
          />
        </div>
      </div>

      {showBubbleProfile ? (
        <div
          className="fixed top-14 right-0 w-40 rounded-md bg-white border border-solid border=[#d1d5db] mr-7 mt-5 overflow-hidden">
          <div className="flex flex-col">
            <button
              type="button"
              className="text-left p-3 transition duration-300 hover:bg-at-a-glance hover:cursor-pointer"
            >
              My Profile
            </button>
            <button
              type="button"
              className="text-left p-3 transition duration-300 hover:bg-at-a-glance hover:cursor-pointer"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

HeaderDesktop.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HeaderDesktop;
